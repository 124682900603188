import Header from "../../components/layout/Header/Header";
import Container from "../../components/layout/Container/Container";

const Contact = () => {
  return (
    <>
      <Header title="Contact" subtitle="get in touch"/>
      <Container>

      </Container>
    </>
  );
};
export default Contact;
